 /* .app {
    font-family: "Lato", sans-serif !important;
  } */

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

:app {
  font-family: 'Nunito Sans', sans-serif;
  line-height: 1.5;
  font-weight: 400;
}

  /* below section is to remove for selection outlined border of prime treact ccomponent */

.p-dropdown {
  box-shadow: none !important;
}
.p-inputtext:focus {
  box-shadow: none !important;
}
.p-button:enabled:focus {
  box-shadow: none !important;
}

.MuiTableCell-body.menu-name-text {
  word-wrap: break-word;
  text-align: left;
  padding: 9px 1px;
  /* width: 200px; */
  font-size: 11px;
}