#rteTools .rte-control-section {
  margin: 0 auto;
}

#rteTools .e-code-mirror::before {
  content: '\e345';
}

#rteTools .e-html-preview::before {
  content: '\e350';
}

#rteTools .CodeMirror-linenumber,
#rteTools .CodeMirror-gutters {
  display: none;
}

.sb-header {
  z-index: 100 !important;
}

#rteTools .e-richtexteditor .e-rte-content .e-content {
  float: left;
  width: 100%;
}

#rteTools .content-wrapper {
  width: auto;
  margin: 0;
}

.highcontrast #rteTools .cm-s-default .cm-tag {
  color: #00ff00;
}
.highcontrast #rteTools .cm-s-default .cm-string {
  color: #ffd939;
}
.highcontrast #rteTools .cm-s-default .cm-attribute {
  color: #f00;
}
.highcontrast #rteTools .CodeMirror {
  background: black;
}

.sb-content.e-view.hide-header {
  top: 0 !important;
}

.sb-header.e-view.hide-header {
  display: none;
}

.e-richtexteditor .e-rte-content .e-content pre {
  padding: 10px;
  background: #f4f5f7;
}

.highcontrast .e-richtexteditor .e-rte-content .e-content pre {
  padding: 10px;
  background: #303030;
}

.action-btn {
  margin-left: 5px;
  margin-right: 5px;
}